import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>Last updated: December 5, 2024</p>

      <h2>Introduction</h2>
      <p>
        Graystone Enterprises Inc. ("Graystone," "we," "us," or "our") values your privacy. This Privacy Policy outlines how we collect, use, share, and protect your personal information when you use our services through our website or applications. By accessing or using our services, you agree to the terms of this Privacy Policy.
      </p>

      <h2>Information We Collect</h2>
      <p>We collect the following types of information to connect you with licensed contractors and facilitate your landscaping needs:</p>
      <h3>1. Personal Information</h3>
      <p>
        - Contact Information: Name, email address, phone number, and physical address. <br />
        - Project Information: Details about your landscaping project, including location, preferences, and any additional information you provide through our interactive forms or AI chat. <br />
        - ZIP Code: Used to geo-locate relevant contractors and facilities.
      </p>
      <h3>2. Automatically Collected Information</h3>
      <p>
        - Device Information: Browser type, IP address, operating system, and device type. <br />
        - Usage Data: Pages visited, time spent on our site, and clickstream data.
      </p>
      <h3>3. SMS Consent</h3>
      <p>
        By opting into our SMS services, you provide explicit consent to receive text messages related to landscaping project updates, notifications.
      </p>
      <p>By opting in to receive text messages from Graystone, you are expressly consenting to receive SMS messages regarding specific services or offers you specifically choose. We value your privacy and trust. Please be assured that your phone number and SMS consent are strictly confidential and will not be sold or shared with any third parties for marketing purposes.
        
      </p>

      <h2>How We Use Your Information</h2>
      <p>
        We use your information to: <br />
        - Geo-locate and connect you with local artificial grass facilities and licensed contractors. <br />
        - Verify contractor licenses and compliance through the California Contractors State License Board (CSLB) API. <br />
        - Securely transmit project details to contractors using RingCentral's messaging API.
      </p>

      <h2>Sharing Your Information</h2>
      <p>We may share your information in the following ways:</p>
      <ul>
        <li>
          <strong>With Contractors:</strong> To fulfill your service requests, we share your name, phone number, ZIP code, and project details with licensed contractors.
        </li>
        <li>
          <strong>Third-Party Service Providers:</strong> We may share your information with providers like RingCentral for secure SMS communication and CSLB for contractor license verification.
        </li>
        <li>
          <strong>Legal Requirements:</strong> We may disclose your information to comply with legal obligations or enforce our agreements.
        </li>
      </ul>

      <h2>SMS Communication and Consent</h2>
      <p>
        <strong>Opt-In:</strong> By checking the consent box during registration, you agree to receive SMS notifications from Graystone. <br />
        <strong>Opt-Out:</strong> You can reply "STOP" to any message to unsubscribe. <br />
        <strong>Help Requests:</strong> Reply "HELP" for assistance or contact us at (844) 444-0469. <br />
        <strong>Messaging Disclosures:</strong> Message and data rates may apply. Messaging frequency varies.
      </p>

      <h2>Your Rights and Choices</h2>
      <p>
        <strong>Access and Updates:</strong> You can request access to or updates for your personal information by contacting us at GraystoneEnterInc@icloud.com. <br />
        <strong>Marketing Preferences:</strong> Opt out of marketing messages by following instructions in any message or contacting us directly.
      </p>

      <h2>Data Security</h2>
      <p>
        We implement industry-standard measures to protect your data, including encryption, secure servers, and restricted access protocols. However, no system is completely secure, and we cannot guarantee absolute security.
      </p>

      <h2>Additional RingCentral Compliance</h2>
      <p>
        We comply with RingCentral’s requirements for SMS communication: <br />
        - SMS consent and phone numbers will not be shared with third parties or affiliates for marketing purposes.<br />
        - Phone numbers are not shared with third parties or affiliates for marketing purposes. <br />
        - Users may opt out of messaging at any time by replying "STOP" or requesting assistance via "HELP."
      </p>
      <p>
        For more information, refer to <a href="https://www.ringcentral.com/legal/last-update-july-29-2024/privacy-notice.html" target="_blank" rel="noopener noreferrer">RingCentral’s Privacy Policy Guidelines</a>.
      </p>

      <h2>Updates to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy periodically. Changes will be posted here, and your continued use of our services constitutes acceptance of the updates.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have questions about this Privacy Policy, contact us at: <br />
        Graystone Enterprises Inc. <br />
        1590 Continental St. Suite 206 <br />
        San Diego, CA, 92154 <br />
        Email: GraystoneEnterInc@icloud.com <br />
        Phone: (844) 444-0469 <br />
        Website: <a href="http://www.Graystone.ai">www.Graystone.Ai</a>
      </p>
    </div>
  );
}

export default PrivacyPolicy;